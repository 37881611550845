import { breakpoints, theme, ThemeOverrides } from "@shapeable/theme";
import { WARM_GREY } from "@shapeable/ui";
import { css } from "styled-components";

export const COLOR_INDICATOR_VALUE_BG = '#9D908C';

export const COLOR_UNCERTAIN = '#F99C1E';
export const COLOR_NO = '#FF0000';
export const COLOR_YES = '#56C02B';


export const COLOR_1_TO_5_1 = '#FF0000';
export const COLOR_1_TO_5_2 = '#F47B20';
export const COLOR_1_TO_5_3 = '#FCAF18';
export const COLOR_1_TO_5_4 = '#BDC02B';
export const COLOR_1_TO_5_5 = '#56C02B';


export const YELLOW = '#EDD184';
export const YELLOW_LIGHT = '#f6e8c2';

export const YELLOW_DARKER = '#BEA76A';
export const YELLOW_ORANGE = '#F79616';

export const ORANGE = "#F15A2C";

export const FOOTER_WARM_GREY = '#9D908C';

export const themeOverrides: ThemeOverrides = {
  font: {
    family: {
      serif: "'Roboto Mono', sans-serif",
      sans: "Roboto Mono, monospace",
      heading: "'Roboto Mono', monospace",
      copy: "'Roboto Mono', monospace",
      mono: "'Roboto Mono', monospace",
      condensed: "'Roboto Mono', sans-serif",
    }
  },
  light: {
    color: {

      // darkBackground: '#202124',
      // lightBackground: '#F8F8F8',
  
      midBrand: YELLOW,
      primary: YELLOW,
  
      highlight: YELLOW_LIGHT,
      // Overriding yellow heading for sliceContentImage 'Powerless empowered'
      heading: '#000000',
  
      link: YELLOW,
      linkHover: ORANGE,

      buttonHover: ORANGE,

      menuActive: YELLOW_DARKER,
    },
  },
  dark: {
    color: {
      highlight: YELLOW_DARKER,
      buttonHover: ORANGE,
      linkHover: ORANGE,
    },

  }
  
};


/* 
--------------------

  Layout Styles
  
  NB: This is the appropriate place to override styling for this specific site, based on BEM-style class name targets 
  
--------------------
*/

export const LayoutStyles = breakpoints({
  base: css`

    .shp--contribute-form {
      .shp--input-checkbox__label {
        color: ${theme.COLOR('light')};
      }

      .shp--input-checkbox__checkbox {
        border-color: #FFF;
        cursor: pointer;
      }
    }

    

    .shp--site-sidebar {
      border-left: none;
    }

    .shp--organisation-powered-by {
      img {
      }

      &:hover {
        filter: none;
      }
    }

    .shp--site-header-layout__navigation-and-controls {
      padding-top: ${theme.UNIT(4)} !important;
    }

    .shp--site-menu-bar {
    }

    .shp--content-entity-thumbnail-link {
      color: ${theme.COLOR('strong')};
      &:hover {
          color: ${theme.COLOR('link-hover')};
      }
      a {
        flex: 1;
      }
    }

    .shp--slice-key-message {
      span {
        font-family: ${theme.FONT('sans')};
        font-size: 0.8em;
      }
    }

    .shp--slice-blurb-video__body {
      font-weight: 300;
    }

    .shp--content-entity-rich-title h2 { color: inherit } 
  `,
  tablet: css`
    .shp--slice-intro {
      font-size: 1.5em;
    }
  `,
  desktop: css`
    
  `,
});