import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { LinkButton as LinkButtonCore, LinkButtonDefaultProps, LinkButtonProps, useCookieLevel } from '@shapeable/ui';
import { useMapUtils } from '@shapeable/maps';
import { startsWith } from 'lodash';

// ** Patches base button to look for #map hash and open map instead ** /

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});


// -------- Components -------->

const My = {
  Container: styled(LinkButtonCore)`${ContainerStyles}`,
};

export const LinkButton: React.FC<LinkButtonProps> = (props) => {
  const { show } = useMapUtils(); 
  const isMapLink = startsWith(props.href, '#map');

  const [ cookies ] = useCookieLevel();

  if (isMapLink && cookies !== 'accept') {
    return null;
  }

  const onClick = isMapLink ? ((event: React.MouseEvent) => {
    show();
    event.preventDefault();
  }) : undefined;

  return (
    <My.Container onClick={onClick} {...props}>{props.children}</My.Container>
  )
};

LinkButton.defaultProps = LinkButtonDefaultProps;