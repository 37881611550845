import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { Economy } from '@shapeable/lli-types';
import { includes } from 'lodash';
import { IndicatorValueBooleanColors, IndicatorValueBooleanIcons, IndicatorValueCanonicalValue } from './indicator-value-boolean';
import { Icon } from '@shapeable/ui';
const cls = classNames('economy-status-icon');

// -------- Types -------->

export type EconomyStatusIconProps = Classable & HasChildren & { 
  entity?: Economy;
  size?: number;
  onClick?: React.MouseEventHandler;
};

export const EconomyStatusIconDefaultProps: Omit<EconomyStatusIconProps, 'entity'> = {
  size: 40,
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    cursor: pointer;
  `,
});

const IconStyles = breakpoints({
  base: css`
    
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Icon: styled(Icon)`${IconStyles}`
};

export const EconomyStatusIcon: Shapeable.FC<EconomyStatusIconProps> = (props) => {
  const { className, children, entity, size } = props;
  const indicatorValues = entity.indicatorValues;
  const targetIndicator = indicatorValues.find(indicatorValue => indicatorValue.indicator.slug === "is-suicide-decriminalised");

  const value = targetIndicator && targetIndicator.value;

  const canonicalValue: IndicatorValueCanonicalValue = !value ? 'UNCERTAIN' : includes(['YES', 'Y'], value.toUpperCase()) ? 'YES' : 'NO';
  const color = IndicatorValueBooleanColors[canonicalValue];
  const icon = IndicatorValueBooleanIcons[canonicalValue];

  return (
    <My.Container className={cls.name(className)}>
      <My.Icon color={color} size={size}>{icon}</My.Icon>
    </My.Container>
  )
};

EconomyStatusIcon.defaultProps = EconomyStatusIconDefaultProps;
EconomyStatusIcon.cls = cls;