import React from 'react';
import { useContext } from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, IndicatorValue } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { Icon, useEntity, ContentNode, CellLabel } from '@shapeable/ui';
import { includes } from 'lodash';
import { QuestionIcon, TickIcon, CrossIcon } from '@shapeable/icons';
import { COLOR_NO, COLOR_YES, COLOR_UNCERTAIN } from '../../theme';
import { LliLogo } from '../elements/lli-logo';
import { isLliMemberContext } from '../context/is-member-context';
import { classNames } from '@shapeable/utils';
const cls = classNames('indicator-value-boolean');

// -------- Types -------->

export type IndicatorValueCanonicalValue = 'YES' | 'NO' | 'UNCERTAIN';

export const IndicatorValueBooleanLabels: Record<IndicatorValueCanonicalValue, React.ReactNode> = {
  YES: 'Yes',
  NO: 'No',
  UNCERTAIN: 'Uncertain',
};

export const IndicatorValueBooleanIcons: Record<IndicatorValueCanonicalValue, React.ReactNode> = {
  YES: <TickIcon />,
  NO: <CrossIcon />,
  UNCERTAIN: <QuestionIcon />,
};

export const IndicatorValueBooleanColors: Record<IndicatorValueCanonicalValue, string> = {
  YES: COLOR_YES,
  NO: COLOR_NO,
  UNCERTAIN: COLOR_UNCERTAIN,
};

export type IndicatorValueBooleanProps = Classable & HasChildren & { 
  entity?: IndicatorValue;
  iconSize?: number;
  isCrisisLineProvision?: boolean;
};

export const IndicatorValueBooleanDefaultProps: Omit<IndicatorValueBooleanProps, 'entity'> = {
  iconSize: 96,
  isCrisisLineProvision: false,
};

// -------- Child Component Props -------->

type ContainerProps = {

}

type LabelAndLogo = {
  isLliMember: boolean;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    font-size: 1em;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    gap: ${theme.UNIT(6)};
  `,
});

const IconStyles = breakpoints({
  base: css`
    flex-shrink: 0;
  `,
});

const BodyStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(4)};
  `,
});

const ContentStyles = breakpoints({
  base: css`
  `,
});

const LabelStyles = breakpoints({
  base: css`
    font-weight: 600;
    font-size: 1em;
  `,
});

const LabelAndLogoStyles = breakpoints({
  base: css`
    ${({ isLliMember }: LabelAndLogo ) => isLliMember && css`
      display: flex;
      align-items: center;
      justify-content: space-between;
    `}
  `,
});

const LliMemberContainerStyles = breakpoints({
  base: css`
    display: flex;
    align-items: center;
    gap: ${theme.UNIT(2)};
  `,
});



const IconLogoStyles = breakpoints({
  base: css`
    flex-shrink: 0;
    ${theme.FILL('light')};
    height: 30px;
    width: auto;
  `,
});

const MemberLabelStyles = breakpoints({
  base: css`
    
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Icon: styled(Icon)`${IconStyles}`,
    Body: styled.div`${BodyStyles}`,
      Content: styled(ContentNode)`${ContentStyles}`,
      LabelAndLogo: styled.div<LabelAndLogo>`${LabelAndLogoStyles}`,
        Label: styled(CellLabel)`${LabelStyles}`,
        LliMemberCotainer: styled.div`${LliMemberContainerStyles}`,
          IconLogo: styled(Icon)`${IconLogoStyles}`,
          MemberLabel: styled.p`${MemberLabelStyles}`,
};  

export const IndicatorValueBoolean: Shapeable.FC<IndicatorValueBooleanProps> = (props) => {
  const { className, children, iconSize, isCrisisLineProvision } = props;
  const entity = useEntity(props.entity);
  const { indicator, value } = entity;

  const isLliMember = useContext(isLliMemberContext)
  
  const canonicalValue: IndicatorValueCanonicalValue = !value ? 'UNCERTAIN' : 
    includes(['YES', 'Y'], value.toUpperCase()) ? 'YES' : 'NO';

  const hasCrisisLine: boolean = value && ['YES', 'Y'].includes(value.toUpperCase());

  const hasMemberBadge = isCrisisLineProvision && hasCrisisLine && isLliMember;

  const { description } = indicator;
  const icon = IndicatorValueBooleanIcons[canonicalValue];
  const color = IndicatorValueBooleanColors[canonicalValue];  
  const label = IndicatorValueBooleanLabels[canonicalValue];


  return (
    <My.Container className={cls.name(className)}>
      <My.Icon size={iconSize} color={color}>{icon}</My.Icon>
      <My.Body>
        <My.Content entity={indicator.description} />
        <My.LabelAndLogo isLliMember={hasMemberBadge}>
        <My.Label>{label}</My.Label>
        {
          hasMemberBadge &&
          <My.LliMemberCotainer>
            <My.MemberLabel>Member of</My.MemberLabel> 
            <My.IconLogo size={66} ><LliLogo/></My.IconLogo>
          </My.LliMemberCotainer>
          
        }
        </My.LabelAndLogo>
      </My.Body>
    </My.Container>
  );
};

IndicatorValueBoolean.defaultProps = IndicatorValueBooleanDefaultProps;
IndicatorValueBoolean.cls = cls;