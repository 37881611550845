import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { Economy } from '@shapeable/lli-types';
import { sortBy, includes } from 'lodash';
import { CellLabel, EntityMultiValueList, Icon, useLang } from '@shapeable/ui';
import { IndicatorValueBooleanColors, IndicatorValueBooleanIcons, IndicatorValueBooleanLabels, IndicatorValueCanonicalValue } from './indicator-value-boolean';
const cls = classNames('country-card-meta');

// -------- Types -------->

// -------- Props -------->

export type CountryCardMetaProps = Classable & HasChildren & { 
  entity?: Economy;
};

export const CountryCardMetaDefaultProps: Omit<CountryCardMetaProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const BodyStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(3)};

    font-weight: inherit;
    padding-top: ${theme.UNIT(3)};
    padding-bottom: ${theme.UNIT(3)};
    ${theme.H_DOT_BG()};
  `,
});

const LabelStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(15)};
    line-height: 1.75em;
    font-weight: 400;
    color: ${theme.COLOR('text')};
  `,
});

const ContentStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${theme.UNIT(2)};
    padding-bottom: ${theme.UNIT(3)};
    ${theme.H_DOT_BG_END()}
  `,
});

const IconStyles = breakpoints({
  base: css`
   flex-shrink: 0;
  `,
});

const LegalSystemStyles = breakpoints({
  base: css`
    
    ;
  `,
});

const ValueLabelStyles = breakpoints({
  base: css`
    text-transform: uppercase;
  `,
});

const PopulationItemStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${theme.UNIT(3)};
    padding-bottom: ${theme.UNIT(3)};
  `,
});

const PopulationStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: row;
    gap: ${theme.UNIT(8)};
    ${theme.H_DOT_BG_END()}
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Body: styled.div`${BodyStyles}`,
      Content: styled.div`${ContentStyles}`,
        Label: styled(CellLabel)`${LabelStyles}`,
        ValueLabel: styled.b`${ValueLabelStyles}`,
        // Value: styled.span`${ValueStyles}`,
      Population: styled.div`${PopulationStyles}`,
      PopulationItem: styled.div`${PopulationItemStyles}`,
      
      
      Icon: styled(Icon)`${IconStyles}`,
      LegalSystem: styled(EntityMultiValueList)`${LegalSystemStyles}`,
};

export const CountryCardMeta: Shapeable.FC<CountryCardMetaProps> = (props) => {
  const { className, children, entity } = props;

  const t = useLang();

  const { name, indicatorValues = [], legalSystems = [] } = entity;

  const hasIndicatorValues = !!indicatorValues.length;
  const hasLegalSystems = !!legalSystems.length;
  
  return (
    <My.Container className={cls.name(className)}>
    <My.Body>
        {
          hasIndicatorValues &&
            indicatorValues.map((indicatorValue, index) => {
              const { value, indicator, numericValue } = indicatorValue;
              const canonicalValue: IndicatorValueCanonicalValue = !value ? 'UNCERTAIN' : includes(['YES', 'Y'], value.toUpperCase()) ? 'YES' : 'NO';
              const icon = IndicatorValueBooleanIcons[canonicalValue];
              const color = IndicatorValueBooleanColors[canonicalValue];
              const label = IndicatorValueBooleanLabels[canonicalValue];
    
              return (
                indicator.slug === 'is-suicide-decriminalised' && (
                  <React.Fragment key={indicator.id}>
                    <My.Label>{t("Is Suicide Decriminalised")}</My.Label>
                    <My.Content>
                      <My.Icon size={24} color={color}>{icon}</My.Icon>
                      <My.ValueLabel>{label}</My.ValueLabel>
                    </My.Content>
                  </React.Fragment>
                )
              );
            })
        }
        {
          hasIndicatorValues && (
            <>
              {
                <My.Label>{t("Population")}</My.Label>}
                <My.Population>
                  {sortBy(indicatorValues, ['indicator.slug']).map((indicatorValue, index) => {
                    const { indicator, numericValue } = indicatorValue;
                    const value = numericValue.toLocaleString();
                    return (
                      <React.Fragment key={indicatorValue.id}>
                        {
                          (indicator.slug === 'population-millions' && numericValue > 0) && (
                            <My.PopulationItem>
                              <My.ValueLabel>{t("Total")}</My.ValueLabel>
                              <My.Label>{value}M</My.Label>
                            </My.PopulationItem>
                          )
                        }
                        {
                          (indicator.slug === 'population-in-need-of-support-per-year' && numericValue > 0) && (
                            <My.PopulationItem>
                              <My.ValueLabel>{t("In Need")}</My.ValueLabel>
                              <My.Label>{value}</My.Label>
                            </My.PopulationItem>
                          )
                        }
                        
                      </React.Fragment>
                    );
                  })
                }
              </My.Population>
            </>
          )
        }
      </My.Body>
    </My.Container>
  )
};

CountryCardMeta.defaultProps = CountryCardMetaDefaultProps;
CountryCardMeta.cls = cls;