import React from 'react';
import { useContext } from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { SidebarButton, useLang, useContributeForm } from '@shapeable/ui';
import { classNames } from '@shapeable/utils';
import { EntityMapToggles, useMapState, useMapUtils } from '@shapeable/maps';
import { WorldMapIconGlyph, ChatTextIconGlyph, FeedbackIconGlyph, useEntityTypeIcons } from '@shapeable/icons';
const cls = classNames('sidebar-buttons');

// -------- Types -------->

export type SidebarButtonsProps = Classable & HasChildren & {
}

export const SidebarButtonsDefaultProps: SidebarButtonsProps = {
};

// -------- Styles -------->

const ButtonStyles = breakpoints({
  base: css`
  
  `,
});

const WorldMapStyles = breakpoints({
  base: css`
    width: 36px;
    height: 22px;
    display: none;
    margin-bottom: ${theme.UNIT(3)};
  `,
  tablet: css`
    display: flex;
  `
});


const TogglesStyles = breakpoints({
  base: css`
    ${theme.H_DOT_BG('light', 2, 6)};
    padding-top: ${theme.UNIT(4)};
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 32px;
    font-size: ${theme.FONT_SIZE(10)};
  `,
});

const FeedbackFormStyles = breakpoints({
  base: css`
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: center;
    width: 32px;
    ${theme.H_DOT_BG('light', 2, 6)};
  `,
});

const ChatBubbleStyles = breakpoints({
  base: css`
    height: 32px;
    width: auto;
    margin-bottom: ${theme.UNIT(4)};
  `,
});

const ContributeLabelStyles = breakpoints({
  base: css`
    font-weight: 500;
    font-size: ${theme.FONT_SIZE(10)};
    margin-bottom: ${theme.UNIT(2)};
    font-family: ${theme.FONT('condensed')};
  `,
});



// -------- Components -------->

const My = {
  Container: React.Fragment,
    Toggles: styled(EntityMapToggles)`${TogglesStyles}`,
    Button: styled(SidebarButton)`${ButtonStyles}`,
      WorldMap: styled(WorldMapIconGlyph)`${WorldMapStyles}`,
    
    FeedbackForm: styled.div`${FeedbackFormStyles}`,
      FeedbackIcon: styled(FeedbackIconGlyph)`${ChatBubbleStyles}`,
      ContributeLabel: styled.span`${ContributeLabelStyles}`,
};

export const SidebarButtons: Shapeable.FC<SidebarButtonsProps> = (props) => {
  const { className } = props;
  const t = useLang();
  const map = useMapUtils();
  const contributeForm = useContributeForm();
  
  return (
    <My.Container>
      {
        map.isVisible && 
        <My.Toggles />
      }
      {
        !map.isVisible && 
        <My.Button onClick={map.showOnClick} icon={<My.WorldMap />}>{t('Explore Map')}</My.Button>
      }
      {
        !map.isVisible && 
        <My.FeedbackForm>
        <My.Button onClick={contributeForm.showOnClick} icon={<My.FeedbackIcon />}>{t('Contact Us')}</My.Button>
        </My.FeedbackForm>
      }
    </My.Container>
  )
};

SidebarButtons.cls = cls;
SidebarButtons.defaultProps = SidebarButtonsDefaultProps;