import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { PageLayoutStandard } from '@shapeable/ui';

// -------- Styles -------->

const LayoutStyles = breakpoints({
  base: css`

    /* override title styles via BEM here to affect spacing */ 
    .shp--page-layout-standard__title {
      color: ${theme.COLOR('light')};
      max-width: 600px;
      font-weight: 400;
      font-size: 2.5em;
    }

    .shp--markdown-content h1 {
      font-size: 2.2em;
      font-weight: 400;
    }

    .shp--list-item-with-icon, .shp--icon {
      flex-shrink: 0;
    }
   

  `,
  tablet: css`
    .shp--page-layout-standard__title {
      font-size: 3.5em;
      line-height: 1.3em;
      h1 {
        color: ${theme.COLOR('light')};
      }
    }

  `,
  desktop: css`
    .shp--page-layout-standard__title {
      font-size: 4.3em;
      line-height: 1.3em;
      h1 {
        color: ${theme.COLOR('light')};
      }
    }
  `
});

// -------- Components -------->

const My = {
  Layout: styled(PageLayoutStandard)`${LayoutStyles}`,
};

export const PageLayoutHome: PageLayoutComponent = (props) => {
  const { className, entity, slices } = props;

  return (
    <My.Layout 
      entity={entity}
      slices={slices}
      headerProps={{ showOverlayGradient: true, aspectRatio: { base: 4/3, landscape: 21 / 9, tablet: 21 / 9 }, showSlideControls: true  }}
      className={className}
    />
  )
};
