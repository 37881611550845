import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent, TContentNode } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { Button, ContentNode, SliceLayoutBoundary, useCookieLevel, useEntity, useLang, useCookieBannerMessage, useSite, useReady, SiteHeaderProvider, SiteHeaderLayout, ComponentPropsProvider } from '@shapeable/ui';
import { ContactForm } from '../entities/contact-form';
import { useCountries } from '../../hooks/use-countries';
import { classNames } from '@shapeable/utils';
const cls = classNames('page-layout-get-involved');

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const CookieSettingsMessageStyles = breakpoints({
  base: css`
  `,
});

const ButtonStyles = breakpoints({
  base: css`
    width: 280px;
    margin-top: ${theme.UNIT(8)};
  `,
});




// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    CookieSettingsMessage: styled(ContentNode)`${CookieSettingsMessageStyles}`,
    CookieSettingsButton: styled(Button)`${ButtonStyles}`,
};

export const PageLayoutGetInvolved: PageLayoutComponent = 
(props) => {
  const { className, children, slices } = props;
  const entity = useEntity(props.entity);

  const site = useSite();

  // we need to also render the form if the page is not hydrated yet to allow
  // Netlify to see forms and form changes during build. 
  const { isReady } = useReady();
  const [ cookies, setCookies ] = useCookieLevel();

  const countries = useCountries();
  const t = useLang();

  const Message = useCookieBannerMessage();

  const message: TContentNode = {
    text: `
Sorry, we cannot show this form due to your current Cookie settings for this website. If you'd like to use this form to contact us, please allow cookies to be used via the "Accept Cookies" button below.

**Note**: You can change your acceptance of cookie usage thereafter in this website at any time using the "Cookie Settings" link in the website footer area.
`,
  };

  return (
    <SiteHeaderProvider value={{ variant: 'default' }}>
      <ComponentPropsProvider value={{
        SiteHeaderLayout: {
          colors: {
            background: 'dark',
          }
        },
      }}>
      <My.Container className={cls.name(className)}>
      <SiteHeaderLayout />
        {slices}
      <SliceLayoutBoundary boundary='content-single' verticalPadding='medium'>
      {
        !isReady || cookies === 'accept' ?
        <ContactForm associatedCountries={countries} /> :
        <>
        <My.CookieSettingsMessage entity={message} />
        <My.CookieSettingsButton onClick={() => { setCookies('accept'); }}>{t('Accept Cookies')}</My.CookieSettingsButton>
        </>
      }
      </SliceLayoutBoundary>
      </My.Container>
      </ComponentPropsProvider>
    </SiteHeaderProvider>
  )
};