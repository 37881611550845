import { 
  PageKnowledgeHubLayout,
  PageKnowledgeHubLayoutProps,
  SliceContentImage,
  SliceContentImageProps,
  SliceHeaderDivider,
  SliceHeaderDividerProps,
  SliceSectionColorBlock,
  SliceSectionColorBlockProps,
  createSliceLayout,
} from '@shapeable/ui';


export const SliceLayoutContentImage = createSliceLayout<SliceContentImageProps>(
  SliceContentImage, {
    props: {
      contentEntityBlockProps: {
        listVariant: 'ticks',
      }
    }
  }
);

export const SliceLayoutKnowledgeHub = createSliceLayout<PageKnowledgeHubLayoutProps>(
  PageKnowledgeHubLayout, {
    boundary: 'content-bleed',
    verticalPadding: 'medium',
    props: {
      filterSize: 'slim',
      filterSpacing: 3,
      showAllTab: true,
      entityTypes: { 
        FeedEntry: { label: 'Global References & Media', filterFieldOptions: { placeholder: '(All Reference Types)' },  filterFieldNames: [ "types", "legalSystems", "methodologies", "economies", ] },
        Post: { label: 'Campaign Assets', filterFieldNames: [ "authors", "type", "economies", "legalSystems", "methodologies"] },
        Video: { label: 'Videos', filterFieldNames: [ "economies" ] },
      }
    }
  }
);

export const SliceLayoutHeader = createSliceLayout<SliceHeaderDividerProps>(
  SliceHeaderDivider, {
    boundary: 'content',
    props: {
    }
  }
);

export const SliceLayoutSection = createSliceLayout(
  SliceSectionColorBlock, {
    boundary: 'none',
  }
);

export const SliceLayoutSectionReverse = createSliceLayout<SliceSectionColorBlockProps>(
  SliceSectionColorBlock, {
    boundary: 'none',
    props: {
      isReversed: true
    }
  }
);
