import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
const cls = classNames('fah-widget');
import { pickBy } from 'lodash';

// -------- Types -------->

export type FahWidgetProps = Classable & HasChildren & {
  countryCode?: string;
  onOverlayClick?: () => void;
}

export const FahWidgetDefaultProps: FahWidgetProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {
  isClickable?: boolean;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({

});


const WidgetStyles = breakpoints({
  base: css`
    z-index: 1200;
    width: 100vw;
    height: calc(100vh - 64px);
    left: 0;
    position: fixed;
    top: 64px;
    cursor: default;

    iframe {
      height: calc(100vh - 64px) !important;
      overflow-x: hidden;
    }
  `,
  tablet: css`
    height: calc(100vh - 64px);
    top: 64px;
    width: calc(100vw - 54px);


`,
  desktop: css`
    top: 84px;
    left: 3vw;
    height: calc(100vh - 64px);
    width: 90vw;

    iframe {
      height: calc(100vh - 104px) !important;
    }
`,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Widget: styled.div`${WidgetStyles}`,
};

export const FahWidgetModal: Shapeable.FC<FahWidgetProps> = (props) => {
  const { className, countryCode, onOverlayClick } = props;
  const widgetConfig = pickBy({
    countryCode
  });

  // const siteHeader = useSiteHeader();
  
  if (typeof window !== 'undefined') {
    React.useLayoutEffect(() => {
      /* @ts-ignore */
      if (typeof Widget !== 'undefined') {
        /* @ts-ignore */
        // document.querySelector('#fah-widget').innerHTML = '';
        Widget.default().render('#fah-widget');
      }
    });
  }

  return (
    <My.Container isClickable={!!onOverlayClick} onClick={onOverlayClick} className={cls.name(className)}>
      {/* <My.CloseButton color="light" hoverColor='primary' size={24} role="button"><Icons.CrossCircle /></My.CloseButton> */}
      <My.Widget id="fah-widget" />
    </My.Container>
  )
};

FahWidgetModal.cls = cls;
FahWidgetModal.defaultProps = FahWidgetDefaultProps;