import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { platformProviderValue, EconomiesProvider as Provider } from "@shapeable/ui";


const QUERY = graphql`
  query EconomiesQuery {
    platform {
      allEconomies {
        id name path slug __typename _schema { label pluralLabel }
        intro { text }
        openGraph { title description { plain } image { url url2x thumbnails { card { url url2x } bubble { url url2x } } } }
        description { id plain text }
        status { id name slug __typename _schema { label pluralLabel } }
        legalSystems { id name slug __typename _schema { label pluralLabel } }
        methodologies { id name label slug __typename _schema { label pluralLabel } }
        decrimStatus { id name slug __typename _schema { label pluralLabel } }
        longitude
        latitude
        isPriority
        isLliMember
        indicatorValues {
          id value numericValue __typename _schema { label pluralLabel }
          indicator { 
            group { id slug name }
            description { text }
            id name title slug precision 
            unit { 
              id slug prefix suffix  __typename
              valueType { id slug } 
            } 
          }
        }
      }
    }
  }
`

export const CountriesProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(QUERY), 'allEconomies' ) }>{children}</Provider>;
};