import { Person } from '@shapeable/lli-types'
import { useActivePerson as useActivePersonCore } from '@shapeable/ui'

const gql = String.raw;

export const useActivePerson = () => useActivePersonCore<Person>({
  queryExtraFields: gql`
    memberOfEconomies { 
      id slug name path __typename
      decrimStatus { id slug name _schema { label } }
      indicatorValues {
        id value numericValue __typename _schema { label pluralLabel }
        indicator { 
          group { id slug name }
          description { text }
          id name title slug precision 
          unit { 
            id slug prefix suffix  __typename
            valueType { id slug } 
          } 
        }
      }
      openGraph { id image { id url } }
    }
  `,
})