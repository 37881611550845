import React from 'react';
import { IconComponent } from '@shapeable/types'

export const TypeIcon: IconComponent = ({ className }) => (
  <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1791_11052)">
    <path d="M62.6087 20.8696C55.7218 20.8696 50.087 26.5044 50.087 33.3914C50.087 40.2783 55.7218 45.9131 62.6087 45.9131C69.4957 45.9131 75.1305 40.2783 75.1305 33.3914C75.1305 26.5044 69.4957 20.8696 62.6087 20.8696ZM60.5218 39.6522L54.2609 33.3914L56.3478 31.3044L60.5218 35.4783L68.8696 27.1305L70.9565 29.2175L60.5218 39.6522Z" fill="black"/>
    <path d="M48 8.34783C69.913 8.34783 87.6522 26.087 87.6522 48C87.6522 69.913 69.913 87.6522 48 87.6522C26.087 87.6522 8.34783 69.913 8.34783 48C8.34783 26.087 26.087 8.34783 48 8.34783ZM48 0C21.4957 0 0 21.4957 0 48C0 74.5043 21.4957 96 48 96C74.5043 96 96 74.5043 96 48C96 21.4957 74.5043 0 48 0Z" fill="black"/>
    <path d="M33.3913 20.8696C26.5044 20.8696 20.8696 26.5044 20.8696 33.3914C20.8696 40.2783 26.5044 45.9131 33.3913 45.9131C40.2783 45.9131 45.913 40.2783 45.913 33.3914C45.913 26.5044 40.2783 20.8696 33.3913 20.8696ZM33.3913 42.7827C28.1739 42.7827 24 38.6088 24 33.3914C24 28.174 28.1739 24.0001 33.3913 24.0001C35.8957 24.0001 38.4 25.0435 40.0696 26.7131L31.3044 35.4783L27.1304 31.3044L25.0435 33.3914L31.3044 39.6522L41.7391 29.2175C42.3652 30.4696 42.7826 31.9305 42.7826 33.3914C42.7826 38.6088 38.6087 42.7827 33.3913 42.7827Z" fill="black"/>
    <path d="M33.3913 50.0869C26.5044 50.0869 20.8696 55.7217 20.8696 62.6087C20.8696 69.4956 26.5044 75.1304 33.3913 75.1304C40.2783 75.1304 45.913 69.4956 45.913 62.6087C45.913 55.7217 40.2783 50.0869 33.3913 50.0869ZM33.3913 72C28.1739 72 24 67.826 24 62.6087C24 57.3913 28.1739 53.2173 33.3913 53.2173C35.8957 53.2173 38.4 54.2608 40.0696 55.9304L31.3044 64.6956L27.1304 60.5217L25.0435 62.6087L31.3044 68.8695L41.7391 58.4347C42.3652 59.6869 42.7826 61.1478 42.7826 62.6087C42.7826 67.826 38.6087 72 33.3913 72Z" fill="black"/>
    <path d="M62.6087 50.0869C55.7218 50.0869 50.087 55.7217 50.087 62.6087C50.087 69.4956 55.7218 75.1304 62.6087 75.1304C69.4957 75.1304 75.1305 69.4956 75.1305 62.6087C75.1305 55.7217 69.4957 50.0869 62.6087 50.0869ZM62.6087 72C57.3913 72 53.2174 67.826 53.2174 62.6087C53.2174 57.3913 57.3913 53.2173 62.6087 53.2173C65.1131 53.2173 67.6174 54.2608 69.287 55.9304L60.5218 64.6956L56.3478 60.5217L54.2609 62.6087L60.5218 68.8695L70.9565 58.4347C71.5826 59.6869 72 61.1478 72 62.6087C72 67.826 67.8261 72 62.6087 72Z" fill="black"/>
    </g>
    <defs>
    <clipPath id="clip0_1791_11052">
    <rect width="96" height="96" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);