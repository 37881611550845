import React from 'react';
import { useState } from 'react';
import styled, { css } from 'styled-components';
import { IconComponent, PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { ComponentPropsProvider, EntityFilters, EntityGrid, SearchBar, SiteHeaderLayout, SiteHeaderProvider, SliceLayoutBoundary, useView } from '@shapeable/ui';
import { useCountries } from '../../hooks/use-countries';
import { Economy, LegalSystem, DecrimStatus } from '@shapeable/lli-types';
import { linkedMatchesFilter } from '../../utils/linked-matches-filter';
import { uniqBy, map } from 'lodash';
import { useMethodologies } from '../../hooks/use-methodologies';
import { useLegalSystems } from '../../hooks/use-legal-systems';
import { includes } from 'lodash';
import { useMemo } from 'react';
import { COLOR_NO, COLOR_UNCERTAIN, COLOR_YES } from '../../theme';
import { IndicatorValueCanonicalValue } from '../entities/indicator-value-boolean';
import { classNames } from '@shapeable/utils';
import { CrossIcon, TickIcon, QuestionIcon } from '@shapeable/icons';

const cls = classNames('page-layout-countries');

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    .shp--card__content .shp--cell-header {
      display: none;
    }
  `,
});

const BodyStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(4)} 0;
  `,
});

const FiltersStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(4)} 0;
  `,
  tablet: css`
    padding: ${theme.UNIT(6)} 0;
  `,
});

const GridStyles = breakpoints({
  base: css`
    
  `,
});

const EmptyViewPageStyles = breakpoints({
  base: css`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: ${theme.UNIT(12)};
    width: 100%;
    height: 400px;
  `,
});

const TickCircleStyles = breakpoints({
  base: css`
    ${theme.FILL(COLOR_YES)}
  `,
});

const CrossCircleStyles = breakpoints({
  base: css`
    ${theme.FILL(COLOR_NO)}
  `,
});

const QuestionCircleStyles = breakpoints({
  base: css`
    ${theme.FILL(COLOR_UNCERTAIN)}
  `,
});

const SearchBarStyles = breakpoints({
  base: css`

  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Body: styled.div`${BodyStyles}`,
      SearchBar: styled(SearchBar)`${SearchBarStyles}`,
      Filters: styled(EntityFilters)`${FiltersStyles}`,
      Grid: styled(EntityGrid)`${GridStyles}`,
      EmptyViewPage: styled.div`${EmptyViewPageStyles}`,

      TickCircle: styled(TickIcon)`${TickCircleStyles}`,
      CrossCircle: styled(CrossIcon)`${CrossCircleStyles}`,
      QuestionCircle: styled(QuestionIcon)`${QuestionCircleStyles}`,
};

// Placed below styled components to allow direct styling of icons with respective colors.
const statusToIconMap: Partial<Record<IndicatorValueCanonicalValue, IconComponent>> = {
  'NO': My.CrossCircle,
  'YES': My.TickCircle,
};

export const PageLayoutCountries: PageLayoutComponent = 
(props) => {
  const { className, children, slices } = props;

  const view = 'economies'
  const { filters, selectedTypes = [], types } = useView(view);
  const allCountries = useCountries() as Economy[];
  const allMethodologies = useMethodologies();
  const allLegalSystems = useLegalSystems();

  const { legalSystems = [], methodologies = [], decrimStatus = [] } = filters;

  const [searchTerm, setSearchTerm] = useState<string>('');

  //augmenting the decrim status with an icon
  const allDecrimStatuses = uniqBy(map(allCountries, country => {
    const status = country.decrimStatus.name.toUpperCase();
    const icon = statusToIconMap[status as IndicatorValueCanonicalValue] || My.QuestionCircle;

    return {
      ...country.decrimStatus,
      __icon: icon,
    };
  }), 'name');


  const filteredAndToggledCountries = useMemo(() => {
    return allCountries.filter(country => {
      const matchesFilters = (
        linkedMatchesFilter(country.legalSystems, legalSystems) &&
        linkedMatchesFilter(country.methodologies, methodologies) &&
        linkedMatchesFilter([country.decrimStatus], decrimStatus)
      );

      const matchesToggle = includes(selectedTypes, 'isPriority') ? country.isPriority : true;
      return matchesFilters && matchesToggle;
    });
  }, [allCountries, legalSystems, methodologies, decrimStatus, selectedTypes]);

  const onChange = (name: string) => { 
    setSearchTerm(name)
   };


   const filteredCountriesFinal = useMemo(() => {
    return filteredAndToggledCountries.filter(country => {
      return country.name.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }, [searchTerm, filteredAndToggledCountries]);

  const hasFilteredCountriesFinal = !!filteredCountriesFinal.length;

  return (
    <SiteHeaderProvider value={{
      variant: 'default',
    }}>
    <ComponentPropsProvider value={{
      SiteHeaderLayout: {
        colors: {
          background: 'dark',
        }
      }
    }}>
    <My.Container className={cls.name(className)}>
    <SiteHeaderLayout />
    {slices}
    <My.Body>
      <SliceLayoutBoundary boundary='content' verticalPadding='none'>
      
      <My.SearchBar
        searchTerm={searchTerm}
        onChange={onChange}
        placeholder='Search Countries'
        variant='bottomBorder'
      />
      <My.Filters
        view={view}
        selects={[
          {
            name: 'decrimStatus',
            items: allDecrimStatuses,
            pluralLabel: 'Decriminalisation Status',
          },
          {
            name: 'legalSystems',
            items: allLegalSystems,
            pluralLabel: 'Types of Law',
          },
          {
            name: 'methodologies',
            items: allMethodologies,
            pluralLabel: 'Methodologies',
          },
        ]}
        results={allCountries}
        typeToggles={[
          { name: 'isPriority', label: 'Focus Countries'},
        ]}
      />
      </SliceLayoutBoundary>
      
      <SliceLayoutBoundary boundary='content-bleed' verticalPadding='none'>

      {
        hasFilteredCountriesFinal ? (
          <My.Grid 
            itemsPerPage={12}
            items={filteredCountriesFinal} 
            desktopFullHdAutoColumns={3}
            desktopLargeAutoColumns={3}
            desktopAutoColumns={3}
            tabletAutoColumns={2}
            landscapeAutoColumns={1}
            /> 
          ) : (
          <My.EmptyViewPage>No Results Found</My.EmptyViewPage>
          )
      }

      </SliceLayoutBoundary>
    </My.Body>
  </My.Container>
  </ComponentPropsProvider>
  </SiteHeaderProvider>
  )
};