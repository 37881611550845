import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { PageLayoutStandard, useEntity } from '@shapeable/ui';
import { classNames } from '@shapeable/utils';
const cls = classNames('page-layout-impact');

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

// -------- Components -------->

const My = {
  Container: styled(PageLayoutStandard)<ContainerProps>`${ContainerStyles}`,
};

export const PageLayoutImpact: PageLayoutComponent = 
(props) => {
  const { className, children, slices } = props;
  const entity = useEntity(props.entity);

  return (
    <My.Container className={cls.name(className)} 
      entity={entity} 
      slices={slices}
      variant='overlay'
    />
    
  )
};