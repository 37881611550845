import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { EntityCard, EntityCardDefaultProps, EntityCardProps } from '@shapeable/ui';
import { Economy } from '@shapeable/lli-types';
import { CountryCardMeta } from './country-card-meta';
const cls = classNames('country-card');

// -------- Types -------->

// -------- Props -------->

export type CountryCardProps = Classable & HasChildren & EntityCardProps & { 
  entity?: Economy;
};

export const CountryCardDefaultProps: Omit<CountryCardProps, 'entity'> = {
  ...EntityCardDefaultProps,
  showDescription: false,
  showTypeHeader: false,
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});


// -------- Components -------->

const My = {
  Container: styled(EntityCard)<ContainerProps>`${ContainerStyles}`,
};

export const CountryCard: Shapeable.FC<CountryCardProps> = (props) => {
  const { className, entity, ...cardProps } = props;
  
  return (
    <My.Container 
      {...cardProps}
      className={cls.name(className)} 
      entity={entity}
      variant='chromeless'
      meta={
        <CountryCardMeta entity={entity} />
      }
    />
  )
};

CountryCard.defaultProps = CountryCardDefaultProps;
CountryCard.cls = cls;