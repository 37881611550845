import { EntityTypeName, Page } from "@shapeable/types";

export const CHARCOAL_GREY = '#3F3F3F';
export const BRIGHT_ORANGE = '#E95A3E';
export const BLACK = '#202124';

export const ECONOMY_LEGISLATIVE_TITLE = "Legislative Reform and Recent Developments";
export const ECONOMY_INTRODUCTION_TITLE = "Introduction";
export const ECONOMY_LEGAL_TITLE = "The Legal System and Historical Context";
export const ECONOMY_PROVISIONS_TITLE = "Provisions Acts and Punishments";
export const ECONOMY_CONTEXT_TITLE = "Cultural, Social, and Religious Context";
export const ECONOMY_ADVOCACY_TITLE = "Advocacy and Stakeholders";
export const ECONOMY_CHANGING_LAW_TITLE  = "How to Change the Law";
export const ECONOMY_CHANGE_TITLE = "The Effects of Change";
export const ECONOMY_CONCLUSION_TITLE = "Conclusion";

export const PAGE_GET_INVOLVED: Page ={
  id: 'get-involved',
  path: '/get-involved',
  name: 'Get Ivolved',
};

export const DEFAULT_MAP_ENTITY_TYPE_NAMES: EntityTypeName[] = [
  'Economy',
]

export const INTRO_FORM_TEXT = "If you would like to contact us about participating in a country program, please use the [Get Involved](/get-involved) form. If you'd like to find out about Lifeline International, you can visit their [Media Centre](https://lifeline-intl.com/media-centre/). And if you'd like to comment on or correct information presented in this website let us know via the form below.";

export const DISCLAIMER = {
  text: "This country profile has been compiled from available research and verified as well as possible. If you wish to supply clarifications, updates, feedback, facts or data, please use the Contact Us form on this page. And if you want your country to take ownership of managing and sharing this information, and progress being made, please register your interest in participating via our [Get Involved](/get-involved) form."
}