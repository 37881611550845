import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { EntityBubbleImage, MenuItem } from '@shapeable/ui';
import { GlobeIconGlyph } from '@shapeable/icons';
import { classNames } from '@shapeable/utils';
import { Person } from '@shapeable/lli-types';
import { useActivePerson } from '../../hooks/use-active-person';
const cls = classNames('user-menu');

// -------- Types -------->

export type UserMenuProps = Classable & HasChildren & {
}

export const UserMenuDefaultProps: UserMenuProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

const MenuItemStyles = breakpoints({
  base: css`
    
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    MenuItem: styled(MenuItem)`${MenuItemStyles}`,
};

export const UserMenu: Shapeable.FC<UserMenuProps> = (props) => {
  const { className } = props;
  const { person, isLoading } = useActivePerson();
  if (!person?.memberOfEconomies?.length) {
    return null;
  }

  return (
    <My.Container className={cls.name(className)}>
    {
      person?.memberOfEconomies.map((economy) => {
        
        const image = <EntityBubbleImage size={32} entity={economy} />;

        return (
          <My.MenuItem 
            isActive={false}
            key={economy.id}
            image={image}
            iconComponent={GlobeIconGlyph}
            href={economy.path}
            label={`${economy.name} Profile`}
          />
        );

      })
    }
    </My.Container>
  )
};

UserMenu.cls = cls;
UserMenu.defaultProps = UserMenuDefaultProps;