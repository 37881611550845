import { graphql, useStaticQuery } from "gatsby";
import * as React from "react"; // tslint:disable-line
import { FeedEntriesProvider as Provider, platformProviderValue } from "@shapeable/ui";

const FEED_ENTRIES_QUERY = graphql`
  query PlatformFeedEntriesQuery {
    platform {
      allFeedEntries {
        name id slug path url __typename _schema { label pluralLabel }
        published author 
        typeLabel
        economies { id slug name __typename _schema { label pluralLabel } openGraph { id image { id url url2x } } }
        types { id name typeLabel slug __typename _schema { label pluralLabel } }
        legalSystems { id name slug __typename _schema { label pluralLabel } }
        methodologies { id name slug __typename _schema { label pluralLabel } }
        openGraph { image { url url2x thumbnails { card { url url2x } } } }
      }
    }
  }
`

export const FeedEntriesProvider: React.FC<any> = ({ children }) => {
  return <Provider value={platformProviderValue(useStaticQuery(FEED_ENTRIES_QUERY), 'allFeedEntries')}>{children}</Provider>;
}