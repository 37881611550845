import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { platformProviderValue, VideosProvider as Provider } from "@shapeable/ui";


const QUERY = graphql`
  query VideosQuery {
    platform {
      allVideos {
        id name path url slug __typename updated created
        # description { text } 
        openGraph { image { url url2x thumbnails { card { url url2x } } } }
        thumbnail { url }
        economies {
          id name slug path __typename _schema { label pluralLabel }
          openGraph { title description { plain } image { thumbnails { full { url url2x } } } }
        }
        people {
          openGraph { image { url url2x } }
          __typename id name slug
          _schema { label pluralLabel }
        }
      }
    }
  }
`

export const VideosProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(QUERY), 'allVideos' ) }>{children}</Provider>;
}