import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { platformProviderValue, PostsProvider as Provider } from "@shapeable/ui";


const QUERY = graphql`
  query PostsQuery {
    platform {
      allPosts { 
        id name path slug __typename
        _schema { label pluralLabel }
        # intro { text }
        # content { text }
        title
        openGraph { image { url url2x thumbnails { card { url url2x } bubble { url url2x } } } }
        legalSystems { id name slug __typename _schema { label pluralLabel } }
        methodologies { id name slug __typename _schema { label pluralLabel } }
        type { id name slug __typename }
        typeLabel
        published
        topics { 
          id name slug __typename _schema { label pluralLabel }
          openGraph { id image { id url url2x } }
        }
        trends { 
          id name slug __typename _schema { label pluralLabel }
          openGraph { id image { id url url2x } }
        }
        people {
          id name slug path 
          organisation { id name }
          linkedin
          position
          photo { id url url2x type }
        }
        economies { id slug name __typename _schema { label pluralLabel } openGraph { id image { id url url2x } } }
        authors {
          openGraph { id image { id url url2x } }
          __typename _schema { label pluralLabel }
          id name slug path
          organisation { id name }
          linkedin
          photo { id url url2x type }
        }
        organisationAuthors {
          id name slug path url
          logoInverted { type url url2x }
          logo { type url url2x }
        }	
      }
    }
  }
`

export const PostsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(QUERY), 'allPosts' ) }>{children}</Provider>;
}